export const LCEI_CACHE_AGE_48_HOURS: number = 1000 * 3600 * 48;
export const LCEI_CACHE_AGE_24_HOURS: number = 1000 * 3600 * 24;
export const LCEI_CACHE_AGE_12_HOURS: number = 1000 * 3600 * 12;
export const LCEI_CACHE_AGE_06_HOURS: number = 1000 * 3600 * 6;
export const LCEI_CACHE_AGE_02_HOURS: number = 1000 * 3600 * 2;
export const LCEI_CACHE_AGE_01_HOURS: number = 1000 * 3600 * 1;

// ------------------------------------------------------------------------------------------------
// --- *** ---
// ------------------------------------------------------------------------------------------------

export const LCEI_CACHE = {
    global: {},
    module: {
        certificateOrder: {
            search: {
                maxCacheCount: 10,
                maxAge: LCEI_CACHE_AGE_06_HOURS
            },
            findOneByID: {
                maxAge: LCEI_CACHE_AGE_24_HOURS
            }
        },
        suggestion: {
            search: {
                maxCacheCount: 10,
                maxAge: LCEI_CACHE_AGE_06_HOURS
            },
            findOneByID: {
                maxAge: LCEI_CACHE_AGE_24_HOURS
            }
        }
    }
};
