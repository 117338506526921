import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import localeFR from '@angular/common/locales/fr';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {XSICapacitorStorageService, XSIStorageService} from '@xs-ionic/base';
import {XSIAppInitializerService, XSIContextService, XSICoreModule} from '@xs-ionic/core';
import {XS_TOKEN_GOOGLE_MAPS_API_KEY} from '@xs-ionic/map';
import {XSAppInitializerBaseService, XSBaseContextService, XSBaseEnvironmentService, XSHttpClientService, XSLoggerService, XSStorageService} from '@xs/base';
import {XS_TOKEN_COMMON_LOADER_DEFAULT_TYPE, XSCommonContextService, XSCommonEnvironmentService, XSLoaderType} from '@xs/common';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {LCEI_MFO_ENDPOINT} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-endpoint.constant';
import {LCEI_STORAGE_KEY_SCHEME, LCEIContextService, LCEIHttpClientService} from '@lce/ionic/shared';
import {LCEIMFORoutingModule} from '@lce/ionic/mfo/app/lcei-mfo-routing.module';
import {LCEIMFOHttpClientService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-http-client.service';
import {LCEIMFOEnvironmentService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-environment.service';
import {LCEIMFOContextService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-context.service';
import {LCEIMFOHttpInterceptor} from '@lce/ionic/mfo/app/core/interceptors/lcei-mfo-http-interceptor';
import {LCEIMFOAppInitializerService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-app-initializer.service';
import {LCEIMFOComponent} from '@lce/ionic/mfo/app/lcei-mfo.component';
import {environment} from '@lce/ionic/mfo/environments/environment';
import {ADDITIONAL_IMPORTS, ADDITIONAL_PROVIDERS} from '@lce/ionic/mfo/environments/environment-common';
import {LCECoreContextService, LCEEnvironmentService, LCEHttpClientService} from '@lce/core';

registerLocaleData(localeFR);

// ------------------------------------------------------------------------------------------------------
// ---------------------------------------- Translation -------------------------------------------------
// ------------------------------------------------------------------------------------------------------

export function HttpLoaderFactory(httpBackend: HttpBackend) {
    return new MultiTranslateHttpLoader(new HttpClient(httpBackend), [
        {prefix: './assets/i18n/xs-common/', suffix: '.json'},
        {prefix: './assets/i18n/xs-payment-base/', suffix: '.json'},
        {prefix: './assets/i18n/xsi-common/', suffix: '.json'},
        {prefix: './assets/i18n/xsi-core/', suffix: '.json'},
        {prefix: './assets/i18n/xs-country/', suffix: '.json'},
        {prefix: './assets/i18n/lce-core/', suffix: '.json'},
        {prefix: './assets/i18n/lcei-shared/', suffix: '.json'},
        {prefix: './assets/i18n/lcei-mfo/', suffix: '.json'}
    ]);
}

// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------

@NgModule({
    declarations: [LCEIMFOComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot({
            backButtonText: ''
        }),
        TranslateModule.forRoot({
            defaultLanguage: environment.common.defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        LoggerModule.forRoot({
            level: NgxLoggerLevel.TRACE,
            timestampFormat: XSLoggerService.TIMESTAMP_FORMAT
        }),
        XSICoreModule.forRoot({
            storageKey: LCEI_STORAGE_KEY_SCHEME,
            storageKeySchemeColorPropertyKey: LCEI_STORAGE_KEY_SCHEME,
            geolocateEndpoint: LCEI_MFO_ENDPOINT.geolocate
        }),
        LCEIMFORoutingModule,
        ADDITIONAL_IMPORTS
    ],
    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        // --- * ---
        ADDITIONAL_PROVIDERS,
        // --- *** ---
        {provide: XSHttpClientService, useExisting: LCEIMFOHttpClientService},
        {provide: LCEHttpClientService, useExisting: LCEIMFOHttpClientService},
        {provide: LCEIHttpClientService, useExisting: LCEIMFOHttpClientService},

        {provide: XSStorageService, useExisting: XSICapacitorStorageService},
        {provide: XSIStorageService, useClass: XSICapacitorStorageService},

        {provide: XSBaseEnvironmentService, useExisting: LCEIMFOEnvironmentService},
        {provide: XSCommonEnvironmentService, useExisting: LCEIMFOEnvironmentService},
        {provide: LCEEnvironmentService, useExisting: LCEIMFOEnvironmentService},

        {provide: XSBaseContextService, useExisting: LCEIMFOContextService},
        {provide: XSCommonContextService, useExisting: LCEIMFOContextService},
        {provide: XSIContextService, useExisting: LCEIMFOContextService},
        {provide: LCEIContextService, useExisting: LCEIMFOContextService},
        // LCEIMFOContextService doesn't inherit from LCECoreContextService, but it implements the same functions, so it should work.
        {provide: LCECoreContextService, useExisting: LCEIMFOContextService},

        {provide: HTTP_INTERCEPTORS, useClass: LCEIMFOHttpInterceptor, multi: true},

        // --- Initializers ---
        {provide: XSAppInitializerBaseService, useExisting: LCEIMFOAppInitializerService},
        {provide: XSIAppInitializerService, useExisting: LCEIMFOAppInitializerService},

        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializerService: LCEIMFOAppInitializerService) => () => appInitializerService.initialize(),
            deps: [LCEIMFOAppInitializerService],
            multi: true // means we could have multiple APP_INITIALIZER.
        },
        {provide: XS_TOKEN_GOOGLE_MAPS_API_KEY, useValue: environment.googleMapsApiKey},
        {provide: XS_TOKEN_COMMON_LOADER_DEFAULT_TYPE, useValue: XSLoaderType.RECTANGLE_BOUNCE}
    ],
    bootstrap: [LCEIMFOComponent]
})
export class LCEIMFOModule {
}
