import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'lcei-authentication-pin-code',
    templateUrl: 'lcei-authentication-pin-code.component.html'
})
export class LCEIAuthenticationPINCodeComponent implements OnInit {

    @Input() styleClass?: string;

    ngOnInit(): void {
    }
}
